<template>
    <AdministrarDuvidasFrequentesIndex v-if="!isCadastrarOuEditar()" /> 
    <AdministrarDuvidasFrequentesForm v-if="isCadastrarOuEditar()" :id="id" />
</template>

<script lang="js">
    import AdministrarDuvidasFrequentesIndex from '../../components/cadastro/AdministrarDuvidasFrequentes.vue'
    import AdministrarDuvidasFrequentesForm from '../../components/cadastro/AdministrarDuvidasFrequentesForm.vue'
    
    export default {
        name: 'AdministrarDuvidasFrequentes',
        components: { AdministrarDuvidasFrequentesIndex, AdministrarDuvidasFrequentesForm },
        data() {
            return {
                isCadastrar: false,
                isAlterar: false,
                id: null,
            };
        },
        created() {
            this.validarRotas();
        },
        updated() {
            this.validarRotas();
        },
        methods: {
            validarRotas() {
                let nomeRota = this.$route.name;
                if (nomeRota === 'cadastrarDuvidasFrequentes') {
                    this.isCadastrar = true;
                } else if (nomeRota === 'alterarDuvidasFrequentes') {
                    
                    this.isAlterar = true;

                    const duvFreqId = this.$route.params.id;

                    console.log('@duvFreqId', duvFreqId);

                    this.id = duvFreqId;

                } else {
                    this.isCadastrar = false;
                    this.isAlterar = false;
                    this.id = null;
                }
            },

            isCadastrarOuEditar() {
                return this.isCadastrar || this.isAlterar;
            },
        },
    }

</script>
