import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/duvidas-frequentes-adm`;

class AdministrarDuvidasFrequentesService {

    consultarDuvidasFrequentes() {

        console.log("Executou o consultar LISTA");

        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/`);
    }

    consultarDuvidaFrequente(id) {

        console.log("Executou o consultar");

        return axios.get(`${API_BASE_URL}${RESOURCE_V1}/${id}`);
    }
    
    cadastrarDuvidaFrequente(duvidaFrequente) {

        console.log("Executou o CAD");

        return axios.post(`${API_BASE_URL}${RESOURCE_V1}/`, duvidaFrequente);
    }

    // publicarDuvidaFrequente(duvidaFrequente) {

    //     if(duvidaFrequente.id == null)
    //         return null;

    //     duvidaFrequente.publicar = true;

    //     return axios.post(`${API_BASE_URL}${RESOURCE_V1}/`, duvidaFrequente);
    // }

    // removerPublicarDividaFrequente(duvidaFrequente) {

    //     if(duvidaFrequente.id == null)
    //         return null;

    //     duvidaFrequente.publicar = false;

    //     return axios.post(`${API_BASE_URL}${RESOURCE_V1}/`, duvidaFrequente);
    // }

    inativarDuvidaFrequente(id){
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}/inativar/${id}`);
    }

    ativarDuvidaFrequente(id){
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}/ativar/${id}`);
    }

    /*
    alterarPrecoAlerta(precoAlerta) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}`, precoAlerta);
    }

    deletarPrecoAlerta(precoAlerta) {
        return axios.put(`${API_BASE_URL}${RESOURCE_V1}/deletar`, precoAlerta);
    }
    */

}

export default new AdministrarDuvidasFrequentesService();