<template>
    <div class="card">
        <Toast />
        <h5>{{ id==null ? 'Cadastrar' : 'Editar' }} Dúvida Frequente</h5>

        <div class="col-12 sm:col-12 lg:col-8 border-panel">
            <div class="grid">
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="duvida"><strong>Dúvida</strong></label>
                    <Textarea 
                        v-model="duvidaFrequente.duvida"
                        :class="['w-full', 'mt-1', {'p-invalid': duvidaInvalido}]"
                        rows="5" cols="30" />
                </div>

                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="resposta"><strong>Resposta </strong></label>
                    <Textarea v-model="duvidaFrequente.resposta" 
                        :class="['w-full', 'mt-1', {'p-invalid': respostasInvalido}]"
                        rows="5" cols="30" />
                </div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="ordem"><strong>Ordem </strong></label>
                    <InputText
                        :class="['w-full', 'mt-1', {'p-invalid': ordemInavlido}]"
                        v-model="duvidaFrequente.ordem" />
                </div>
               
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="publicado"><strong>Publicado: </strong></label>
                    <Dropdown class="w-full mt-1" id="publicado" v-model="publicado" :options="publicadoOpcoes" optionLabel="nome" />
                </div>
                

                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="visualizacoes"><strong>Visualizações: </strong></label>
                    <label>{{ duvidaFrequente.visualizacoes }}</label>
                </div>
                

            </div>


            <br />

            <div class="flex align-items-center">
                <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2" @click="dialog = true;" />
                <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-success" @click="salvar()" />
            </div>
        </div>
    </div>

    <Dialog v-model:visible="dialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <span>Tem certeza de que deseja cancelar?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text p-button-danger" @click="dialog = false;" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="voltar()" />
        </template>
    </Dialog>
</template>

<script>
    import DuvidaFrequenteService from '../../service/AdministrarDuvidasFrequentes';
    import Textarea from 'primevue/textarea';

    export default {
        name: 'AdministrarDuvidasFrequenteForm',
        props: {
            id: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                duvidaFrequente: {},
                duvidaInvalido : false,
                respostaInvalido: false,
                ordermInvalido : false,
                dialog : false,

                toastTipoErro: 'error',
                toastTituloFalha: 'Falha',
                toastTipoSucesso: 'success',
                toastTituloSucesso: 'Sucesso',
                toastLife: 3000,
                toastDetalheAtuazlizacaoSucesso: 'A dúvida frequente foi atualizado com sucesso!',
                toastDetalheAtualizacaoFalha: 'Falha ao atualizar o dúvida frequente!',

                // ativo: null,
                // ativoOpcoes: [
                //      {nome: 'Sim', valor: true},
                //      {nome: 'Não', valor: false}
                // ],

                publicado: null,
                publicadoOpcoes: [
                    {nome: 'Sim', valor: true},
                    {nome: 'Não', valor: false}
                ],


            }
        },
        created() {
            this.consultarDuvidaFrequente();
        },
        methods: {
            consultarDuvidaFrequente() {

                if(this.id == null){
                    this.publicado = {nome: 'Não', valor: false};
                    this.duvidaFrequente.visualizacoes = 0;
                    return null;
                }
                    

                DuvidaFrequenteService.consultarDuvidaFrequente(this.id)
                    .then(({ data }) => {
                        if (data) {
                            this.duvidaFrequente = data;

                            if(this.duvidaFrequente.publicado)
                                this.publicado = {nome: 'Sim', valor: true};
                            else
                                this.publicado = {nome: 'Não', valor: false};


                        }
                    })
                    .catch(error => {
                         //this.voltar();
                         console.error(error);
                    });
            },

            voltar() {
                 this.$router.push(`/adm-duvidas-frequentes`);
            },

            validarCampos() {
                let isValid = true;

                if (!this.duvidaFrequente.duvida) {
                     this.duvidaInvalido = true;
                     isValid = false;
                }

                if (!this.duvidaFrequente.resposta) {
                     this.respostaInvalido = true;
                     isValid = false;
                }

                if (!this.duvidaFrequente.ordem) {
                     this.ordemInvalido = true;
                     isValid = false;
                }

                if (!isValid) {
                     this.abrirToast(this.toastTipoErro, this.toastTituloFalha, 'Preencher os campos obrigatórios.', this.toastLife);
                     return;
                }

                return isValid;
            },

            salvar() {
                if (!this.validarCampos()) return;

                let publicado = this.publicado;
                this.duvidaFrequente.publicado = publicado.valor;
            
                DuvidaFrequenteService.cadastrarDuvidaFrequente(this.duvidaFrequente)
                    .then(response => {
                        
                        this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, this.toastDetalheAtuazlizacaoSucesso, this.toastLife);

                        setTimeout(() => {
                            this.voltar();
                        }, "1000");


                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTituloFalha, error, this.toastLife);
                    });
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                 this.$toast.add({
                     severity: tipo,
                     summary: titulo,
                     detail: descricao,
                     life: tempo
                 });
            },

           
           
        },

    }
</script>

<style>
</style>
