<template>
    <div class="card">
        <Toast />
        <h5>Dúvidas Frequentes</h5>

        <DataTable :value="duvidasFrequentes"
                :paginator="true" 
                :rows="10"
                class="p-datatable-gridlines" 
                dataKey="id" 
                v-model:filters="filters1"
                :filters="filters1" 
                filterDisplay="menu" 
                :rowHover="true" 
                :loading="loading" 
                responsiveLayout="scroll"
				:globalFilterFields="['duvida', 'resposta']" 
                >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <div>

                        <Button type="button" 
                                label="Cadastrar" 
                                class="p-button-info mb-2 mr-2" 
                                icon="pi pi-plus" 
                                @click="cadastrar()" />

                        <Button type="button" 
                                icon="pi pi-filter-slash" 
                                label="Limpar" 
                                class="p-button-outlined mb-2" 
                                @click="clearFilter1() "/>
                        
                        
                    </div>
                    <div>
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filters1['global'].value"
                                    placeholder="" 
                                    style="width: 250px;" />
                        </span> 
                    </div>
                </div>
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>

            <Column field="dataCadastro" header="Data Cadastro" sortable="true" style="width:7rem; text-align: center;">
                <template #body="{ data }">
                    {{ formatDate(data.dataCadastro, 'DD/MM/YYYY') }}
                </template>
            </Column>

            <Column field="duvida" header="Dúvida"  />    
            <Column field="resposta" header="Resposta" />    
            <Column field="visualizacoes" header="Visualizações" sortable="true" style="width: 4rem; text-align: center;" />    
            <Column field="ordem" header="Ordem" sortable="true" style="width: 4rem; text-align: center;" />    
            
            <Column field="publicado" header="Publicado" sortable="true" style="width: 4rem; text-align: center;">
                <template #body="{data}">
                    <span v-if="data.publicado" >Sim</span>
                    <span v-if="!data.publicado" >Não</span>
                </template>
            </Column>
            
            <Column style="width: 14rem; text-align: center;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button icon="pi pi-pencil" 
                            title="Alterar" 
                            class="p-button-rounded p-button-success mr-2" 
                            @click="alterar(data.id)" />
                        <Button v-if="!data.ativo" 
                            title="Ativar" 
                            icon="pi pi-check" 
                            class="p-button-rounded p-button-info mr-2" 
                            @click="confirmarAtivarOuInativar(data)" />
                        <Button v-if="data.ativo" 
                            title="Inativar" 
                            icon="pi pi-times" 
                            class="p-button-rounded p-button-danger mr-2" 
                            @click="confirmarAtivarOuInativar(data)" />
                        <!-- <Button v-if="!data.publicado" 
                            icon="pi pi-pencil" 
                            title="Publicar" 
                            class="p-button-rounded p-button-success mr-2" 
                            @click="publicar(data)" /> -->
                        <!-- <Button v-if="data.publicado"
                            icon="pi pi-pencil" 
                            title="Remover Publicação" 
                            class="p-button-rounded p-button-danger mr-2" 
                            @click="publicar(data.id)" /> -->
                    </div>
                </template>
            </Column>


        </DataTable>

       
    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <span>Deseja <strong>{{ duvidaFrequente.ativo ? 'inativar' : 'ativar' }}</strong> a Dúvida Frequente id: <strong>{{ duvidaFrequente.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text p-button-danger" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text " @click="ativarOuInativar()" />
        </template>
    </Dialog>
    
</template>

<script>
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import DuvidasFrequentesService from '../../service/AdministrarDuvidasFrequentes';
    import Formatacao from '../../utilities/Formatacao';
    

    export default {
        name: "AdministrarDuvidasFrequentes",
        data() {
            return {
                duvidasFrequentes: [],
                duvidaFrequente : null,
                filters1: null,
                loading: false,
                totalRegistro: 0,
                qtdRegistro: 10,
                pagina: 0,
                ativarOuInativarDialog: false,
                disabled: true,
                //cooperado: {},
                // toastTipoErro: "error",
                // toastTituloFalha: "Falha",
                // toastTipoSucesso: "success",
                // toastTituloSucesso: "Sucesso",
                //toastLife: 3000,
                //matricula: null,
                //historicoDialog: false,
                palavraChave: '',
                ativoOuInativo: null,
                ordenacao: '',
                publicado:['Não', 'Sim'],

                toastTipoErro: 'error',
                toastTituloFalha: 'Falha',
                toastTipoSucesso: 'success',
                toastTituloSucesso: 'Sucesso',
                toastLife: 3000,
            };
        },

        created() {
			this.initFilters1();
            this.buscarDuvidasFrequentes();
		},
		mounted() {
			this.loading1 = false;
		},
        methods: {
            carregarLoading() {
                this.loading = !this.loading;
            },
            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'duvida': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'resposta': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'dataPublicacao': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
				}
			},
            clearFilter1() {
                // this.palavraChave = '';
                // this.cooperados = [];
                // this.ativoOuInativo = null;
                // this.botaoAtivoInativo = 'Listar ativos';
                // this.ordenacao = '';
                
                //this.filters1 = null;
                //this.buscarDuvidasFrequentes();
                this.initFilters1();
            },
            onPage(event) {
                this.pagina = event.page;
                this.qtdRegistro = event.rows;
                this.buscarDuvidasFrequentes();
            },
            cadastrar(){
                this.$router.push(`/adm-duvidas-frequentes/cadastro`);
            },
            alterar(id){
                this.$router.push(`/adm-duvidas-frequentes/${id}`);
            },

            // abrirToast(tipo, titulo, descricao, tempo) {
            //     this.$toast.add({
            //         severity: tipo,
            //         summary: titulo,
            //         detail: descricao,
            //         life: tempo
            //     });
            // },
            // confirmarAtivarOuInativar(cooperado) {
            //     this.cooperado = cooperado;
            //     this.ativarOuInativarDialog = true;
            // },
            // ativarOuInativar() {
            //     if (this.cooperado.ativo) {
            //         CooperadoService.inativar(this.cooperado.matricula)
            //             .then(response => {
            //                 this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, `O cooperado ${this.cooperado.nome} foi inativado com sucesso!`, this.toastLife);
            //                 this.buscarCooperados();
            //                 this.ativarOuInativarDialog = false;
            //             })
            //             .catch(error => {
            //                 this.abrirToast(this.toastTipoErro, this.toastTipoErro, `Falha ao inativar o cooperado ${this.cooperado.nome}!`, this.toastLife);
            //             });
            //     }
            //     else {
            //         CooperadoService.ativar(this.cooperado.matricula)
            //             .then(response => {
            //                 this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, `O cooperado ${this.cooperado.nome} foi ativado com sucesso!`, this.toastLife);
            //                 this.buscarCooperados();
            //                 this.ativarOuInativarDialog = false;
            //             })
            //             .catch(error => {
            //                 this.abrirToast(this.toastTipoErro, this.toastTipoErro, `Falha ao inativar o cooperado ${this.cooperado.nome}!`, this.toastLife);
            //             });
            //     }
            // },
            // alterar(matricula) {
            //     this.$router.push(`/cooperados/${matricula}`);
            // },
            // abrirHistoricoAcesso(matricula) {
            //     this.historicoDialog = true;
            //     this.matricula = matricula;
            // },

            // fecharHistoricoAcesso() {
            //     this.historicoDialog = false;
            //     this.matricula = null;
            // },

            buscarDuvidasFrequentes() {

                //console.log('Duvidas Frequentes - Método buscarDuvidasFrequentes');
                this.duvidasFrequentes = [];
                // if ((this.palavraChave.length > 2 
                //         || (!isNaN(this.palavraChave) && this.palavraChave != ''))
                //         && (this.ativoOuInativo != '' 
                //         || this.ativoOuInativo != null)) {
                //     this.filtrarCooperados();
                // } else if (this.palavraChave == '') {
                //     this.filtrarCooperados();
                // }
                DuvidasFrequentesService.consultarDuvidasFrequentes()
                    .then(({ data }) => {
                         if (data) {
                            console.log("DuvidasFrequentes: ", data);
                            this.duvidasFrequentes = data;
                         }
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:"error",
                            detail: error.response.data.message_error, 
                            life: 5000
                        });

                        if(error.response.data.code_error===403){
                            setTimeout(() => { 
                                this.$router.push('/');
                            }, 3000);
                            
                        }
                    });
            },

            // filtrarCooperados() {
            //     this.carregarLoading();
            //     let filtro = {
            //         'palavraChave': this.palavraChave,
            //         'ativo': this.ativoOuInativo,
            //     }
            //     let params = {
            //         'page': this.pagina,
            //         'size': this.qtdRegistro,
            //         'sort': this.ordenacao,
            //     }
            //     CooperadoService.buscarCooperados(filtro, params)
            //         .then(({ data }) => {
            //             if (data) {
            //                 this.cooperados = data.content;
            //                 this.qtdRegistro = data.pageable.pageSize;
            //                 this.totalRegistro = data.totalElements;
            //                 this.carregarLoading();
            //             }
            //         });
            // },

            // listarAtivos() {
            //     this.ativoOuInativo = true;
            //     this.filtrarCooperados();
            // },

            // listarInativos() {
            //     this.ativoOuInativo = false;
            //     this.filtrarCooperados();
            // },

            // onSort(event) {
            //     let direcao = 'ASC';
            //     if (event.sortOrder == -1) {
            //         direcao = 'DESC';
            //     }
            //     if (event.sortField.toLowerCase() == 'estabelecimento.nome') {
            //         event.sortField = 'estabelecimento';
            //     }
            //     this.ordenacao = event.sortField + ',' + direcao;

            //     this.filtrarCooperados();
            // },

            confirmarAtivarOuInativar(data){
                this.duvidaFrequente = data;
                this.ativarOuInativarDialog = true;
            },

            ativarOuInativar(){
                if (this.duvidaFrequente.ativo) {
                    
                    DuvidasFrequentesService.inativarDuvidaFrequente(this.duvidaFrequente.id)
                        .then(response => {
                            //this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, `A dúvida frequente foi inativado com sucesso!`, this.toastLife);
                            this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, "A dúvida frequente foi inativada com sucesso!.", this.toastLife);
                            this.buscarDuvidasFrequentes();
                            this.ativarOuInativarDialog = false;
                        })
                        .catch(error => {
                            this.abrirToast(this.toastTipoErro, this.toastTituloFalha, `Falha ao inativar a dúvida frequente!`, this.toastLife);
                    });
                }
                else {
                    DuvidasFrequentesService.ativarDuvidaFrequente(this.duvidaFrequente.id)
                        .then(response => {
                            //this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, `A dúvida frequente foi ativado com sucesso!`, this.toastLife);
                            this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, "A dúvida frequente foi ativada com sucesso!.", this.toastLife);
                            this.buscarDuvidasFrequentes();
                            this.ativarOuInativarDialog = false;
                        })
                        .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTituloFalha, `Falha ao inativar a dúvida frequente!`, this.toastLife);
                    });
                }
            },

            // inativar(id){
            //     DuvidasFrequentesService.inativarDuvidaFrequente(id)
            //         .then(response => {
                        
            //             this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, "A dúvida frequente foi inativada.", this.toastLife);

            //         })
            //         .catch(error => {
            //             this.abrirToast(this.toastTipoErro, this.toastTituloFalha, error, this.toastLife);
            //         });
            // },

            // ativar(id){
            //     DuvidasFrequentesService.ativarDuvidaFrequente(id)
            //         .then(response => {
                        
            //             this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, "A dúvida frequente foi ativada.", this.toastLife);

            //         })
            //         .catch(error => {
            //             this.abrirToast(this.toastTipoErro, this.toastTituloFalha, error, this.toastLife);
            //         });
            // },

            formatDate(data, format) {
                return Formatacao.formatDateCustom(data, format);
            },

            abrirToast(tipo, titulo, descricao, tempo) {
                 this.$toast.add({
                     severity: tipo,
                     summary: titulo,
                     detail: descricao,
                     life: tempo
                 });
            },

        },
        components: {  }
}
</script>

<!-- <style scoped lang="scss">
    @import '../../assets/demo/styles/badges.scss';
</style> -->
